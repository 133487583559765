*{
  --adm-color-primary: #075E54;
}
.app {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.top {
  flex: 0;
}
[data-prefers-color-scheme=dark] body {
  background: #262629;
}

body {
  background: #fafbfc;
  transition: none;
  overflow: hidden;
}
.planexpirepopup .adm-dialog-body p{
  color: #fff;
}
.planexpirepopup .adm-dialog-body{
  background: linear-gradient(135deg, #075e54, #04befe);
  color: #fff
}
.body {
  flex: 1;
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  /* margin-top: 50px; */
  padding-bottom: 100px;
}

.top {
  position: sticky;
  top:0;
  width: 100%;
  background: var(--adm-color-background);
  color: var(--adm-color-text);
  height: 50px;
  z-index: 1;
  padding-bottom: 5px;
}

.bottom {
  flex: 0 1;
  border-top: solid 1px #b5b5b5;
  padding-bottom: 15px;
  padding-top: 10px;
  position: fixed;
  bottom: 0;
  width: 100%;
  background: var(--adm-color-background);
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  border: 0;
  background: var(--adm-color-background);
  width: 100vw;
  height: 100vh;
  z-index: 99999999;
}

.date-picker-container{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chartMainDiv {
  width: 100%;
  padding: 10px;
  overflow-y: scroll;
  height: calc(100vh - 130px);
}
.profileMainDiv{
  overflow-y: scroll;
  height: calc(100vh - 50px);
}

.bar-chart {
  width: 100%;
  height: 300px;
  position: relative;
}

.chartDiv {
  background: var(--adm-color-background);
  width: 100%;
  align-items: center;
  justify-content: center;
  /* display: flex; */
  padding: 15px;
  border-radius: 15px;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  margin-bottom: 15px;
}

.gridRowData {
  display: flex;
  margin-left: -7.5px;
  margin-right: -7.5px;
  margin-bottom: 7.5px;
}

.gridRowData .cards,
.gridRowDataOne .cards {
  width: 100%;
  padding: 15px;
  margin: 7.5px;
  border-radius: 15px;
  position: relative;
}

.failedCard {
  background-color: rgb(0, 0, 0);
  color: #fff;
}

.readCard {
  background-color: rgb(37,211,102);
  color: #fff;
}

.deliveredCard {
  background-color: rgb(18,140,126);
  color: #fff;
}

.sentCard {
  background-color: rgb(7,94,84);
  color: #fff;
  
}

.gridRowDataOne {
  display: flex;
  margin-left: -7.5px;
  margin-right: -7.5px;
}

[data-prefers-color-scheme=light] a{
  text-decoration: none !important;
  color: #075E54;
}
[data-prefers-color-scheme=dark] a {
  text-decoration: none !important;
  color: #128C7E;
}

[data-prefers-color-scheme=light] .adm-tab-bar-item {
  color: #075E54;
}
[data-prefers-color-scheme=dark] .adm-tab-bar-item {
  color: #128C7E;
}
.adm-tab-bar-item-active{
  font-weight: bold;
}
.adm-tab-bar-item .adm-badge-wrapper{
  padding-top: 3px;
  padding-bottom: 3px;
}
.adm-tab-bar-item-active .adm-badge-wrapper {
  background: -moz-linear-gradient(top, #075E54 0%, #128C7E 100%);
  background: -webkit-linear-gradient(top, #075E54 0%, #128C7E 100%);
  background: linear-gradient(to bottom, #075E54 0%, #128C7E 100%);
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 15px;
  color: #fff;
  transition: all 240ms ease-in-out;
}

.searchHeader {
  padding: 12px;
  background: var(--adm-color-background);
  display: flex;
  align-items: center;
  position: sticky;
  z-index: 999999;
  top: 0;
  left: 0;
}
.searchLeft {
  flex: auto;
}
.searchRight {
  flex: none;
  padding-left: 12px;
}
.placeholder {
  padding-top: 30vh;
  text-align: center;
  color: var(--adm-color-weak);
}
.loadingWrapper {
  font-size: 24px;
  margin-bottom: 24px;
}
.listTime {
  float: right;
  font-size: 12px;
}


.customerList.unread:after {
  content: "";
  position: absolute;
  right: 16px;
  background: #075E54;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  bottom: 23.4%;
}

.customerList.unread .last-conv {
  font-weight: bold;
  color: #075E54;
}

.customer-avatar {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  background-color: #075E54;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #fff;
}

.no-more{
  width: 100%;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 100px;
}

.rotate-icon {
  transform: rotate(90deg);
  transition: transform 0.3s;
}

.adm-search-bar .adm-search-bar-input-box{
  background: rgb(7 94 84 / 19%);
}

.adm-search-bar-input-box-icon {
  color: #075E54 !important;
}

.flaotingButtons {
  right: 0vw;
}

.flaotingButtonsOne{
  right: 0vw;
  z-index: 999999;
}
.addScreenMainDiv{
  padding: 15px;
  text-align: center;
}

.phone-input-container {
  width: 100%;
}

input.form-control.phone-input {
  border: 0 !important;
}

.flag-dropdown {
  border: unset !important;
  background: #fff !important;
}

.ant-select-selector{
  border: unset !important;
  box-shadow: unset !important;
}

.menuPopup .adm-popup-body.adm-popup-body-position-bottom {
  bottom: 15px;
  border-radius: 10px;
  margin: 15px;
  width: calc(100% - 30px);
  height: auto !important;
}

[data-prefers-color-scheme=dark] .imageSendBtn, [data-prefers-color-scheme=dark] .documentSendBtn, [data-prefers-color-scheme=dark] .videoSendBtn, [data-prefers-color-scheme=dark] .templateListBtn {
  background: #414141;
  color: #fff;
  width: 60px;
  height: 60px;
  font-size: 35px;
  border-radius: 60px;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 0;
}
.imageSendBtn, .documentSendBtn, .videoSendBtn, .templateListBtn {
  border: unset;
  font-size: 11px;
}
.imageSendBtn div{
  color: #3380e2;
}

.documentSendBtn div{
  color: #7763eb;
}

.videoSendBtn div{
  color: #c5396a;
}

.templateListBtn div{
  color: #3380e2;
}

[data-prefers-color-scheme=light] .imageSendBtn div, [data-prefers-color-scheme=light] .documentSendBtn div, [data-prefers-color-scheme=light] .videoSendBtn div, [data-prefers-color-scheme=light] .templateListBtn div {
  background: #ccc;
  width: 60px;
  height: 60px;
  font-size: 35px;
  border-radius: 60px;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 0;
}
