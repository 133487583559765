.my-chats .adm-nav-bar-title {
    z-index: 1;
    margin-left: -50px;
}

.message {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    margin: 5px 0;
    position: relative;
}
.my-message .mainboddiv {
    max-width: 75%;
    align-self: end;
    padding: 5px;
    border-radius: 10px;
    min-width: 30%;
}
.my-message {
    align-self: flex-end;
}
[data-prefers-color-scheme=dark] body .my-message .mainboddiv:before {
    background: linear-gradient(135deg, #075E54, #075E54 50%, #0000 0, #0000);
}
[data-prefers-color-scheme=dark] body .my-message .mainboddiv {
    background-color: #075E54;
    color: #fff;
}
[data-prefers-color-scheme=light] body .my-message .mainboddiv:before {
    background: linear-gradient(135deg, #d9fdd3, #d9fdd3 50%, #0000 0, #0000);
}
[data-prefers-color-scheme=light] body .my-message .mainboddiv {
    background-color: #d9fdd3;
}

.my-message .mainboddiv:before {
    content: "";
    height: 10px;
    position: absolute;
    right: 2px;
    top: 15px;
    width: 10px;
}

.other-message .mainboddiv:before {
    content: "";
    height: 10px;
    position: absolute;
    left: 2px;
    top: 15px;
    width: 10px;
}

.other-message {
    align-self: flex-start;
}

.other-message .mainboddiv {
    max-width: 75%;
    align-self: start;
    padding: 5px;
    border-radius: 10px;
    min-width: 30%;
}

[data-prefers-color-scheme=dark] body .other-message .mainboddiv:before {
    background: linear-gradient(225deg,#202c33,#202c33 50%,#0000 0,#0000);
}
[data-prefers-color-scheme=dark] body .other-message .mainboddiv {
    background-color: #202c33;
    color: #fff;
}
[data-prefers-color-scheme=light] body .other-message .mainboddiv:before {
    background: linear-gradient(225deg,#fff,#fff 50%,#0000 0,#0000);
}
[data-prefers-color-scheme=light] body .other-message .mainboddiv {
    background-color: #FFFFFF;
}

.message-text {
    font-size: 14px;
}
.message-intractive{
    font-size: 14px;
    /* min-width: 250px; */
}

.message-time {
    font-size: 12px;
    color: #888;
    text-align: right;
    margin-top: 5px;
}

[data-prefers-color-scheme=dark] body .chatboxBackground {
    background-image: url('../darkchat.png');
    background-repeat: repeat;
}
[data-prefers-color-scheme=light] body .chatboxBackground {
    background-image: url('../lightchat.png');
    background-repeat: repeat;
}

.templatebuttons, .quickreply, .moreBtn, .urlbtn, .phonebtn {
    height: 40px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #d6d6d6;
}

.prvbodytext {
    display: block;
    white-space: pre-line;
    text-align: left;
    font-size: 14px;
}

.templatemessage .adm-input, .templatemessage .ant-select {
    border: 1px solid #666;
    border-radius: 4px;
    height: 35px;
    padding-left: 4px;
}

a.adm-popup-close-icon {
    color: #fff !important;
    background: red;
    border-radius: 40px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    position: absolute !important;
    right: 10px !important;
    left: unset !important;
}

.dotloading {
    width: 100%;
    text-align: center;
    height: 50px;
}

.old-message-overlay {
    line-height: 12px;
    text-align: center;
}
.tagsDiv span {
    padding: 10px;
    margin: 5px;
}
.loadMoreBtn{
    border-radius: 4px !important;
    width: auto !important;
    height: auto !important;
    padding: 10px;
    background-color: #075E54 !important;
}

.footertext {
    color: #777777;
    padding-bottom: 10px;
}
.headertext {
    font-weight: bold;
    font-size: 15px;
}

.closeFlooat {
    position: absolute;
    right: 10px;
    font-size: 25px;
    top: 0;
}

.section-title {
    font-size: 20px;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
}
.row-description {
    font-size: 12px;
    opacity: 0.8;
}
.row-title {
    font-size: 16px;
}
.recepent {
    font-size: 14px;
    padding: 3px;
    position: relative;
    top: 2px;
}
span.message_read {
    color: blue;
}

.previewContent, .messagePreviewImages, .messagePreviewVideos {
    max-height: 300px;
    overflow: hidden;
    border-radius: 8px;
    /* margin-bottom: 10px; */
}

button.adm-button.adm-button-default.adm-button-shape-circle.sendtemplateButton {
    position: fixed;
    bottom: 15px;
    right: 15px;
    border: none;
    width: 40px;
    height: 40px;
    line-height: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #075E54;
    color: #fff;
    border-radius: 40px;
}

.sendBtn {
    padding: 0;
    margin: 0;
    width: 40px;
    height: 40px;
    background: #075E54;
    color: #fff;
    border: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 0;
    border-radius: 40px;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    border-bottom-left-radius: 40px;
}

.sendBtnOne {
    padding: 0;
    margin: 0;
    width: 70px;
    height: 60px;
    background: #075E54;
    color: #fff;
    border: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 0;
    border-radius: 60px;
    border-top-left-radius: 60px;
    border-top-right-radius: 60px;
    border-bottom-right-radius: 60px;
    border-bottom-left-radius: 60px;
}

.templatePreviewBtn {
    background: transparent;
    border: unset;
}
.loadingInner {
    width: 100%;
    text-align: center;
    font-size: 30px;
}

.thumbnailofimagespreview {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
    justify-content: flex-start;
    padding: 10px;
    white-space: nowrap;
    margin-bottom: 70px;
}

.closePreviewBtn {
    position: fixed;
    top: 5px;
    right: 5px;
    background: red;
    color: #fff;
    border-radius: 100%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    line-height: 0;
}

.thumbnailofimagespreview div {
    width: 70px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    position: relative;
    margin-right: 10px;
}

.thumbnailofimagespreview img {
    object-fit: cover !important;
}

span.removeImageBtn {
    position: absolute;
    z-index: 1;
    color: red;
    width: calc(100% - 10px);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    left: 0px;
    background: rgb(0 0 0 / 80%);
    border-radius: 10px;
}

.addMore span {
    width: 60px;
    display: flex;
    justify-content: center;
}

.custom-footer .sendBtn {
    position: fixed;
    bottom: 15px;
    right: 15px;
}

.addMore {
    background: #ddd;
    font-size: 30px;
}

.thumbnailofimagespreview div.active img{
    border: 5px solid #ddd;
}

.messagePreviewImages img {
    border-radius: 10px;
}
.templatemessage {
    padding: 15px;
    margin-bottom: 100px;
}

ul.notesul {
    list-style-type: none;
    margin-left: 0;
    padding-left: 0;
}
ul.notesul li {
    background: #ddd;
    padding: 10px;
    margin-top: 10px;
    border-radius: 10px;
}